import React from "react";
import DefaultLayout from "../../components/DefaultLayout";
import Hero from "../../components/bricks/Hero";
import FeatureOverview from "../../components/FeatureOverview";
import ThemeBox from "../../components/bricks/ThemeBox";
import {themeBright, themeDark, themePurple} from "../../page-styles/landingpage.css";
import ThemedSingleFeature from "../../components/bricks/ThemedSingleFeature";
import {Box} from "../../components/xui/Box";
import {Link} from "gatsby";
import YoutubeVideo from "../../components/bricks/YoutubeVideo";
import {Quote} from "../../components/ui/Quote";

const Features = ({location}) => (
  <DefaultLayout
    title="Meet Decky - Codecks integrating with Discord"
    description="This advanced Discord integration allows you to get the most out of your team and audience."
    location={location}
  >
    <Hero size="sm" title="Discord Integration" subline="Our bot Decky is here to help">
      <Box width="100%">
        <YoutubeVideo videoId="A41zSXg8jZQ" />
      </Box>
    </Hero>
    <ThemeBox theme={themeBright} withBorder>
      <ThemedSingleFeature title="Out of the box" childAsDescription img="discord.png">
        Codecks features a built-in Discord bot called Decky, created to support busy game
        developers with community management. It can be activated and set up in just a few minutes
        thanks to a simple and clear interface — no need to dig into complicated documentation.
      </ThemedSingleFeature>

      <ThemedSingleFeature
        title="Energize your fans"
        childAsDescription
        img="discord-feedback.png"
        imgRight
      >
        Collect feature requests, ideas and bug reports directly from your Discord community and let
        fans help you identify the most relevant messages through upvoting. These contributions live
        inside Codecks and can be scheduled and managed like any other card.
      </ThemedSingleFeature>

      <Quote authorName="Kerri King" companyName="Freedom Games" img="kerri-king" width="md">
        Decky has been an amazing tool for helping us make sure that our players feel like their
        problems and suggestions are being heard by the game devs. It's also been wonderful in
        helping us on the game dev side of things keep all of those suggestions and reports ordered
        properly and work through them efficiently!
      </Quote>
    </ThemeBox>

    <ThemeBox theme={themeDark} withBorder title={null}>
      <ThemedSingleFeature title="A playful approach" childAsDescription img="discord-7.png">
        Leaderboards are summaries of the most popular contributions submitted through Decky, as
        voted by your community. They list the top entries based on their vote count and can be
        customized according to your needs and the size of your Discord server.
      </ThemedSingleFeature>

      <ThemedSingleFeature title="In the loop" childAsDescription img="discord-3.png" imgRight>
        Decky can share your progress, inform fans on how their feedback is being used in your work,
        and help you collect any follow-up details they might have, without having to dig through
        old DM conversations.
      </ThemedSingleFeature>
    </ThemeBox>
    <ThemeBox theme={themePurple} width="lg">
      <Box color="primary" size="xl" textAlign="center">
        For a more in-depth look at how Decky can be integrated in the workflow of a game dev
        studio, read this post{" "}
        <Box
          as={Link}
          color="white"
          bold
          hover_color="accent"
          to="/blog/discord-for-game-developers/"
        >
          on our blog
        </Box>
        .
      </Box>
    </ThemeBox>
    <ThemeBox theme={themeBright} title="Got Steam®?" width="lg">
      <Box color="primary" size="lg" textAlign="center">
        Alongside Decky, Codecks also offers another free Discord bot called{" "}
        <Box as={Link} color="accent" bold hover_color="purple600" to="/steam-bot/">
          Steamy
        </Box>
        , specifically made to track reviews posted for any game available on Steam. Check out how
        it works in this short video:
      </Box>
      <YoutubeVideo videoId="D1pPWxNYufg" />
    </ThemeBox>
    <FeatureOverview />
  </DefaultLayout>
);

export default Features;
